<template>
    <v-card>
        <v-toolbar class="darken-1" color="blue" dark>
            <v-toolbar-title>
                Details
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <th>
                        Mountain Name
                    </th>
                    <th>
                        Country
                    </th>
                    <th>
                        Travel Date
                    </th>
                    <tbody>
                    <tr v-for="branch in branchData.mountainCustomerLogList " :key="branch.checkInDate">
                        <td>
                            {{branch.mountainName}}
                        </td>

                        <td>
                            {{branch.country}}
                        </td>
                        <td>
                            {{branch.travelDate | moment("DD-MMM-YYYY")}}
                        </td>
                    </tr>

                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "MountaineeringDetails",
        props: ["index","branchData"],
        data() {

        },
        destroyed() {
        },
        mounted() {

        },
        methods: {

        }
    };
</script>

<style scoped lang="scss">
td{
    text-align:center;
}
</style>